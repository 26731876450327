import React from 'react';
  
const signin = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'Right',
        alignItems: 'Right',
        height: '100vh'
      }}
    >
      <h1>Sign Up</h1>
    </div>
  );
};
  
export default signin;